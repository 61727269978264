import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchUsers(ctx, params) {
      return useJwt.getUsersByAdmin(params).then(response => response);
    },
    getUserDetail(ctx, id) {
      return useJwt.getUserDetailByAdmin(id).then(response => response);
    },
    updateUserSubscription(ctx, data) {
      return useJwt.updateUserSubscriptionByAdmin(data).then(response => response);
    },
    changeUserPassword(ctx, data) {
      return useJwt.changeUserPasswordByAdmin(data).then(response => response);
    },

    updateUser(ctx, data) {
      return useJwt.updateUser(data).then(response => response);
    },

  },
};
