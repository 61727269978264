import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import userStoreModule from '../userStoreModule';

export default function useUserList() {
  const APARTMENT_STORE_MODULE_NAME = "admin";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, userStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    province: null,
    district: null,
    ward: null,
    address: '',
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '100px',
    },
    {
      label: 'Sửa',
      field: 'action',
      width: '60px',
      sortable: false,
    },
    {
      label: 'Họ tên',
      field: 'name',
    },
    {
      label: 'Số điện thoại',
      field: 'phone',
    },
    {
      label: 'Email',
      field: 'email',
    },

    {
      label: 'Đăng nhập lần cuối',
      field: 'lastLogin',
    },
    {
      label: 'Ngày đăng ký',
      field: 'created_at',
    },
    {
      label: 'Ngày hết hạn',
      field: 'expiredIn',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const active = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const vm = getCurrentInstance().proxy;

  //   API Call

  const fetchUsers = () => {
    store
      .dispatch('admin/fetchUsers', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items.map(object => ({
          ...object,
          maxSlots: object.roles && object.roles.length > 0 ? object.roles[0].maxSlots : 0,
          maxRoomSlots: object.roles && object.roles.length > 0 ? object.roles[0].maxRoomSlots : 0,
          maxBedSlots: object.roles && object.roles.length > 0 ? object.roles[0].maxBedSlots : 0,
          zaloZnsCount: object.roles && object.roles.length > 0 ? object.roles[0].zaloZnsCount : 0,
          tingeeTransactionCount: object.roles && object.roles.length > 0 ? object.roles[0].tingeeTransactionCount : 0,
          expiredIn: object.roles && object.roles.length > 0 ? object.roles[0].expiredIn : 0,
        }));
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchUsers();
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('user-admin/importUsers', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('user-admin/exportUsers', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteUsers = users => {
    store
      .dispatch('user-admin/deleteUsers', {
        ids: users.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const syncDataFromItro = selectedItem => {
    vm.$bvModal
      .msgBoxConfirm(
        `Bạn đang thực hiện thao tác đồng bộ dữ liệu cho ${selectedItem.name} có số điện thoại là ${selectedItem.phone}. Bạn có chắc chắn muốn tiếp tục không?`,
        {
          title: `Đồng bộ dữ liệu ${selectedItem.name}`,
          okTitle: 'Đồng bộ ngay',
          cancelTitle: 'Huỷ',
          okVariant: 'primary',
          cancelVariant: 'outline-danger',
        },
      )
      .then(value => {
        if (value) {
          store
            .dispatch('user-admin/syncUser', {
              id: selectedItem.id,
            })
            .then(() => {
              toastification.showToastSuccess("Vui lòng đợi, dữ liệu đang được đồng bộ");
            })
            .catch(error => {
              toastification.showToastError(error);
            });
        }
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });

  return {
    analytics,
    item,
    columns,
    rows,
    active,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    fetchUsers,
    deleteUsers,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    syncDataFromItro,
  };
}
